<template>
    <modal
        :closeModalOnBackdropClicked="false"
        @close="$emit('close')"
        class="add-photo-modal"
    >
        <template v-slot:title>
            <h1
                v-if="isPhotoUploadModal"
                class="f-20-black f-extrabold mb-2"
            >
                Add Photo
            </h1>
            <h1
                v-else
                class="f-20-black f-extrabold mb-2"
            >
                Add PDF Documents
            </h1>
        </template>
        <template v-slot:description>
            <p
                class="f-13-darkgrey"
                v-if="isPhotoUploadModal"
            >
                You can upload up to 10 photos.
            </p>
            <p
                class="f-13-darkgrey"
                v-else
            >
                You can upload up to 10 PDF files.
            </p>
        </template>
        <template #body>
            <div class="w-100">
                <label
                    class="upload-drop-area column-center pointer"
                    ref="droparea"
                    @dragenter.stop.prevent="dropActive"
                    @dragover.stop.prevent
                    @dragleave.stop.prevent="dropInActive"
                    @drop.stop.prevent="dropInActive"
                >
                    <input
                        type="file"
                        name="file_upload"
                        ref="uploadInput"
                        multiple="multiple"
                        :accept="imageAccept"
                        @change="fileInputHandler"
                    />
                    <div class="column-center">
                        <span class="round-iconX43 main-green-lighter">
                            <upload-icon class="i-green"/>
                        </span>
                        <span class="f-12-black f-sbold mt-8">
                            Click to upload
                        </span>
                    </div>
                </label>
            </div>
        </template>
        <template #footer>
            <div class="mt-20">
                <content-upload-preview
                    v-for="(file, index) in toUploadFiles"
                    :file="file"
                    :key="index"
                    :filesInProgress="filesInProgress"
                />
            </div>
            <div class="row-end mt-7">
                <button
                    :class="[!toStartUpload && !filesInProgressLength ? 'border-button' : 'primary-button']"
                    :disabled="!toStartUpload && !filesInProgressLength"
                    @click="doneFileUploading"
                >
                    <template v-if="filesInProgressLength">
                        <loader
                            size="mini"
                            loaderColor="white"
                            class="s-align-5"
                        />
                    </template>
                    <template v-else>
                        Done
                    </template>
                </button>
            </div>
        </template>
    </modal>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import ContentUploadPreview from "../../components/Profile/ContentUploadPreview";
import Modal from "../../ui/modal/Modal";
import UploadIcon from "../../icons/UploadIcon";
import {CONTENT_TYPES, IMAGE_TYPE, MEGABYTE_SIZE, PDF_TYPE} from "../../constants";

const MAX_LIMIT_UPLOAD_IMAGE = 10;

export default {
    name: 'UploadFileManufacturerModal',
    components: {
        Modal,
        UploadIcon,
        ContentUploadPreview
    },
    props: {
        uploadedType: {
            type: String,
            default: 'photo'
        },
        cardId: {
            type: [String, Number],
            required: false
        },
        btnId: {
            type: [String, Number],
            required: false
        },
    },
    data() {
        return {
            toStartUpload: false,
            toUploadFiles: [],
            filesInProgress: [],
            photoFormData: new FormData(),
        }
    },
    computed: {
        ...mapGetters({
            photoContent: 'content/photoContentSection',
            documentContent: 'content/documentContentSection',
            isDarkMode: 'cards/darkModeIsActive'
        }),
        isPhotoUploadModal() {
            return this.uploadedType === CONTENT_TYPES.PHOTO;
        },
        imageAccept() {
            return this.isPhotoUploadModal ? 'image/png, image/gif, image/jpeg' : 'application/pdf'
        },
        uploadFilesLength() {
            return this.toUploadFiles.length;
        },
        filesInProgressLength() {
            return !!this.filesInProgress.length;
        },
    },
    methods: {
        ...mapActions({
            addContentForButton: "manufacturer/addContentForButton",
            getButtonContent: "manufacturer/getButtonContent"
        }),
        initFormData() {
            this.photoFormData = new FormData();
            const sectionName = this.isPhotoUploadModal ? 'Photo' : 'Documents';
            const sectionType = this.isPhotoUploadModal ? CONTENT_TYPES.PHOTO : CONTENT_TYPES.DOCUMENT;
            this.photoFormData.append('section_name', sectionName);
            this.photoFormData.append('section_type', sectionType);
        },
        dropActive() {
            this.$refs.droparea.style.backgroundColor = 'rgba(229, 241, 238, 0.3)';
        },
        dropInActive() {
            this.$refs.droparea.style.backgroundColor = this.isDarkMode ? '#081116' : '#ffffff';
        },
        prepeareFilesForUploading(files) {
            let allFilesSize = [...files].reduce((acc, el) =>  acc += el.size ?? 0, 0);
            if (allFilesSize > (MEGABYTE_SIZE * 25)) {
                this.$store.dispatch(
                    'notifications/SHOW_ERROR_NOTIFICATION',
                    `All uploaded files should be less than 25Mb! \n You want to upload ${(allFilesSize / MEGABYTE_SIZE).toFixed()} Mb.`
                );
                return;
            }
            // Selected files for upload must be dynamic number (choosen file + uploaded files earlier).
            if (files.length > MAX_LIMIT_UPLOAD_IMAGE ) {
                this.$store.dispatch(
                    'notifications/SHOW_ERROR_NOTIFICATION',
                    'The maximum number of files is 10!'
                );
                return;
            }
            files.forEach((file, idx) => {
                const keyFiles = this.isPhotoUploadModal ? 'photos' : 'documents';
                this.photoFormData.append(`${keyFiles}[${idx}][file]`, file);
                if (!this.isPhotoUploadModal) {
                    this.photoFormData.append(`${keyFiles}[${idx}][type]`, 'pdf')
                }
                this.filesInProgress.push(file.name);
                this.toUploadFiles.push(file);
            })
            this.uploadFiles();
        },
        fileInputHandler(event) {
            if (!this.$refs.uploadInput.files.length) {
                return false;
            }
            this.prepeareFilesForUploading(this.$refs.uploadInput.files);
        },
        dropHandler(event) {
            let files = event.dataTransfer.files;
            if (!files.length) {
                return false;
            }
            this.prepeareFilesForUploading(files);
        },
        uploadFiles() {
            this.toStartUpload = true;
            this.addContentForButton({id: this.btnId, data: this.photoFormData}).then(res => {
                if (res?.id){
                    this.filesInProgress = [];
                    this.initFormData();
                    this.$emit('onReload', res)
                }
            })
        },
        doneFileUploading() {
            if (this.toStartUpload && !this.filesInProgressLength) {
                this.$emit('close');
            }
        }
    },
    created() {
        this.initFormData();
    },
    mounted() {
        if (this.$refs.droparea) {
            this.$refs.droparea.addEventListener('drop', this.dropHandler, false);
        }
    },
    beforeUnmount() {
        this.$refs.droparea.removeEventListener('drop', this.dropHandler, false);
    }
}
</script>
