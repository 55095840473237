<template>
    <div>
        <div
            class="lg-12 row-baseline pointer mb-8 f-15-grey"
            @click="$router.back()"
        >
            <arrow-shewron
                :transform="180"
                width="14"
                height="14"
                class="i-green mr-2"
            />
            QR Admin
        </div>
        <div class="mt-5 profile-card manufacturer-items__buttons" v-if="manufacturerItem">
            <div class="row-between">
                <div class="column-start">
                    <p class="f-18-black">Name: <span class="ml-2">{{ manufacturerItem?.name }}</span></p>
                    <p>Model: <span class="ml-2">{{ manufacturerItem?.model }}</span></p>
                    <p>Serial Number: <span class="ml-2">{{ manufacturerItem?.serial_number }}</span></p>
                </div>
                <button
                    class="icon-button manufacturer-items__buttons-settings-btn"
                    @click.stop="toggleManufacturerModal(manufacturerItem)"
                >
                    <settings-icon/>
                </button>
            </div>
            <hr>
            <div class="public-cover-photo" v-if="manufacturerItem?.cover">
                <div class="public-cover-photo-container">
                    <img :src="manufacturerItem.cover"/>
                </div>
            </div>
            <div class="row-between row-baseline">
                <div>
                    <p class="f-14-black f-black">Add cover picture</p>
                    <p class="f-14-grey f-medium mt-1">Image dimensions should be at least 1620 x 600</p>
                </div>
                <div
                    :class="[
                                'sm-12',
                                mScreen ? 'mt-3 s-align-1' : 's-align-9'
                            ]"
                >
                    <primary-button
                        @click="openCoverImageModal"
                    >
                        Add cover picture
                    </primary-button>
                    <danger-button
                        v-if="manufacturerItem.cover"
                        @click="deleteCoverImage()"
                        class="ml-2"
                    >
                        Delete
                    </danger-button>
                </div>
            </div>
            <hr>
            <button
                class="primary-button lg-2"
                @click.stop="showAddContentModal"
            >
                Add button
            </button>
            <draggable
                v-if="manufacturerItem.buttons"
                v-model="manufacturerItem.buttons"
                :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
                item-key="name"
                @end="saveContentPosition"
            >
                <template #item="{ element: btn }">
                    <div class="row-start mb-2">
                        <div class="column-start" style="flex: 1">
                            <button
                                @click="btnHandler(btn)"
                                draggable="true"
                                class="primary-button manufacturer-items__buttons-item w-100"
                                :style="{backgroundColor: btn.data.bgColor, color: btn?.data?.txtColor}"
                            >
                                <div
                                    v-if="btn?.type === TYPE_FIELDS.phone"
                                    class="phone-icon-container"
                                    :style="{borderColor: btn?.data?.txtColor}"
                                >
                                    <phone-icon :style="{fill: btn?.data?.txtColor}"/>
                                </div>
                                <arrow-shewron
                                    v-if="btn.type === TYPE_FIELDS.contentButton"
                                    class="i-green mr-1"
                                    :style="{fill: btn?.data?.txtColor}"
                                    :transform="showContent?.id === btn?.id ? '-90' : '90'"
                                />
                                {{ btn.name }}
                            </button>
                            <transition name="fade-content">
                                <div
                                    :class="['w-100 content-preview pt-5', {'content-preview__show' : showContent?.id === btn?.id}]"
                                    v-if="showContent?.id === btn?.id"
                                >
                                    <div class="row-end">
                                        <button class="primary-button" @click.stop="addContentModal = true">Add
                                            content
                                        </button>
                                    </div>
                                    <loader v-if="isLoadingCreate"/>
                                    <ManufacturerContentSection
                                        v-else-if="buttonContent && rerenderComponent && !isLoadingCreate"
                                        :content="buttonContent"
                                        :canDeleteOrEdit="true"
                                        @onReload="getContent"
                                    />
                                </div>
                            </transition>
                        </div>
                        <button @click.stop="editHandler(btn)"
                                class="icon-button manufacturer-items__buttons-settings-btn">
                            <settings-icon/>
                        </button>
                        <button @click.stop="removeModal = btn"
                                class="icon-button manufacturer-items__buttons-remove-btn">
                            <trash-icon/>
                        </button>
                    </div>
                </template>
            </draggable>
        </div>
        <add-content-modal
            :title="'Add Button'"
            :description="''"
            v-if="addModal"
            @close="addModal = false"
            :isManufacturer="true"
            @addContentButton="() => toggleDynamicModal(TYPE_FIELDS.contentButton)"
            @addPhoneNumber="() => toggleDynamicModal(TYPE_FIELDS.phone)"
            @addSocialLinks="() => toggleDynamicModal(TYPE_FIELDS.link)"
        />
        <add-content-modal
            :title="'Add Content'"
            :description="''"
            v-if="addContentModal"
            :isManufacturerContent="true"
            @close="addContentModal = false"
            @addEmbedVideo="() => addContentSubmit(CONTENT_TYPES.VIDEO)"
            @addDocuments="() => addContentSubmit(CONTENT_TYPES.DOCUMENT)"
            @addPhoto="() => addContentSubmit(CONTENT_TYPES.PHOTO)"
        />
        <upload-file-manufacturer-modal
            v-if="typeContentModal === CONTENT_TYPES.PHOTO || typeContentModal === CONTENT_TYPES.DOCUMENT"
            :uploadedType="modalType"
            @close="typeContentModal = false"
            :isManufacturer="true"
            :btnId="showContent?.id"
            @onReload="reloadContentBtn"
        />
        <add-video-modal
            v-if="typeContentModal === CONTENT_TYPES.VIDEO"
            @close="typeContentModal = false"
            @onUpload="addContent"
        />
        <AddButtonManufactureModal
            v-if="!!addDynamicModal"
            :type="addDynamicModal"
            @close="addDynamicModal = null, editData = null"
            @onUpload="addButton"
            :isLoading="isLoadingCreate"
            :editData="editData"
        />
        <remove-modal
            v-if="!!removeModal"
            :data="removeModal"
            :name="removeModal?.name"
            @close="removeModal = null"
            @submit="removeBtn"
        />
        <create-manufacturer-modal
            v-if="isManufacturerModal"
            :edit-data="editManufacturerData"
            @onClose="toggleManufacturerModal"
            @submit="editManufacturerHandler"
        />
        <add-cover-photo-modal
            id="coverModal"
            v-if="showCoverPictureModal"
            @addCoverPhoto="showPreview"
            @close="showCoverPictureModal = false"
            :is-loading="isLoadingCreate"
        />
    </div>
</template>

<script>
import ArrowShewron from "../../icons/ArrowShewron";
import {mapActions, mapGetters} from "vuex";
import AddContentModal from "../../components/Profile/AddContentModal";
import {CONTENT_TYPES, TYPE_FIELDS} from "../../constants";
import AddVideoModal from "../../components/Profile/AddVideoModal";
import AddLinksModal from "../../components/Profile/AddLinksModal";
import ManufacturerItemCard from "../../components/ManufacturerItemCard";
import PhoneIcon from "../../icons/PhoneIcon";
import UploadFileManufacturerModal from "./UploadFileManufacturerModal";
import AddButtonManufactureModal from "./AddButtonManufactureModal";
import TrashIcon from "../../icons/TrashIcon";
import RemoveModal from "../../ui/modal/RemoveModal";
import SettingsIcon from "../../icons/SettingsIcon";
import CreateManufacturerModal from "../../ui/modal/CreateManufacturerModal";
import draggable from "vuedraggable";
import DangerButton from "../../ui/base/DangerButton";
import AddCoverPhotoModal from "../../components/Profile/AddCoverPhotoModal";
import ManufacturerContentSection from "../../components/ManufacturerContentSection";

const SECTION_CONTENT_NAME = {
    VIDEO: 'Video',
    PHOTO: 'Photo',
    DOCUMENTS: 'Documents'
}
export default {
    name: "ManufacturerItem",
    components: {
        ArrowShewron,
        AddContentModal,
        UploadFileManufacturerModal,
        AddVideoModal,
        AddLinksModal,
        ManufacturerItemCard,
        PhoneIcon,
        AddButtonManufactureModal,
        TrashIcon,
        RemoveModal,
        SettingsIcon,
        CreateManufacturerModal,
        DangerButton,
        draggable,
        AddCoverPhotoModal,
        ManufacturerContentSection
    },
    data() {
        return {
            isManufacturerModal: false,
            editManufacturerData: null,
            TYPE_FIELDS,
            CONTENT_TYPES,
            SECTION_CONTENT_NAME,
            addModal: false,
            addContentModal: false,
            uploadFileModal: false,
            videoModal: false,
            addDynamicModal: null,
            modalType: '',
            manufacturerItem: null,
            editData: null,
            removeModal: null,
            showContent: null,
            typeContentModal: null,
            dragStart: null,
            coverImage: null,
            coverImagePreview: null,
            showCoverPictureModal: false,
            buttonContent: null,
            rerenderComponent: true
        }
    },
    computed: {
        ...mapGetters({
            manufacturerItemData: "manufacturer/manufacturerItem",
            isLoadingCreate: "manufacturer/isLoadingCreate"
        }),
        manufactureId() {
            return this.$route.params.id;
        }
    },
    watch: {
        buttonContent(val, oldVal) {
            if (!oldVal && !!val){
                this.forceRender();
            }else if (!!val && !!oldVal && val.length !== oldVal.length){
                this.forceRender();
            }
        }
    },
    methods: {
        ...mapActions({
            getManufactureItemsByCode: "manufacturer/getManufactureItemsByCode",
            updateManufactureItem: "manufacturer/updateManufacturer",
            addButtonToManufacturerItem: "manufacturer/addButtonToManufacturerItem",
            updateButtonManufacturerItem: "manufacturer/updateButtonManufacturerItem",
            deleteButtonManufacturerItem: "manufacturer/deleteButtonManufacturerItem",
            addContentForButton: "manufacturer/addContentForButton",
            getButtonContent: "manufacturer/getButtonContent"
        }),
        async forceRender() {
            this.rerenderComponent = false;
            await this.$nextTick();
            this.rerenderComponent = true;
        },
        openCoverImageModal() {
            this.showCoverPictureModal = true;
        },
        showPreview(val, src) {
            const formData = new FormData();
            formData.append('cover', val);
            this.updateManufactureItem({id: this.manufacturerItem?.id, data: formData}).then(res => {
                if (res) {
                    this.getManufacturerItem();
                    this.coverImage = val;
                    this.coverImagePreview = src;
                    this.showCoverPictureModal = false;
                }
            })

        },
        deleteCoverImage() {
            this.updateManufactureItem({id: this.manufacturerItem?.id, data: {cover: null}}).then(res => {
                if (res) {
                    this.getManufacturerItem();
                }
            })
        },
        toggleManufacturerModal(item) {
            this.editManufacturerData = item?.id ? item : null;
            this.isManufacturerModal = !this.isManufacturerModal;
        },
        saveContentPosition() {
            const data = JSON.parse(JSON.stringify(this.manufacturerItem));
            data.buttons = data.buttons.map((el, idx) => {
                const obj = {...el, position: idx + 1};
                delete obj.sections;
                return obj
            })
            if (typeof data.cover === 'string') {
                delete data.cover;
            }
            if (data.buttons?.length <= 1) return;
            this.updateManufactureItem({id: this.manufacturerItem?.id, data}).then(res => {
                res && this.getManufacturerItem();
            })
        },
        editManufacturerHandler(data) {
            this.updateManufactureItem({id: this.manufacturerItem?.id, data}).then(res => {
                if (res) {
                    this.getManufacturerItem();
                    this.toggleManufacturerModal();
                }
            })
        },
        btnHandler(item) {
            if (item?.type === TYPE_FIELDS.contentButton) {
                const val = this.showContent?.id === item?.id ? null : item;
                this.showContent = val;
                if (val) {
                    this.getButtonContent(item.id).then(res => {
                        this.buttonContent = res;
                    })
                } else {
                    this.buttonContent = null;
                }
            }
        },
        editHandler(item) {
            this.addDynamicModal = item.type;
            this.editData = item;
        },
        removeBtn() {
            if (!this.removeModal?.id) return;
            this.deleteButtonManufacturerItem(this.removeModal?.id).then(res => {
                if (res) {
                    this.removeModal = null;
                    this.getManufacturerItem();
                }
            });
        },
        showAddContentModal() {
            this.addModal = true;
        },
        toggleDynamicModal(val) {
            this.addModal = false;
            this.addDynamicModal = val;
            !val && (this.editData = null);
        },
        addContentSubmit(val) {
            this.modalType = val;
            this.typeContentModal = val;
            this.addContentModal = false;
        },
        addDocuments() {
            this.addModal = false;
            this.typeContentModal = CONTENT_TYPES.DOCUMENT;
            this.modalType = CONTENT_TYPES.DOCUMENT;
        },
        addContent(val) {
            this.addContentForButton({id: this.showContent?.id, data: val}).then(res => {
                if (res?.id) {
                    this.typeContentModal = false;
                    this.modalType = null;
                    this.reloadContentBtn(res);
                }
            })
        },
        reloadContentBtn(file) {
            if (this.buttonContent && Array.isArray(this.buttonContent)) {
                this.buttonContent = [...this.buttonContent, file];
            } else {
                this.buttonContent = [file];
            }
        },
        getContent() {
            this.getButtonContent(this.showContent?.id).then(res => {
                this.buttonContent = res;
            })
        },
        addButton(val, type) {
            const data = JSON.parse(JSON.stringify(this.manufacturerItem));
            const newBtn = {
                manufacturer_item_id: data?.id,
                position: data?.buttons?.length + 1 ?? 1,
            }
            newBtn.type = type;
            newBtn.name = val?.name ?? '';
            newBtn.data = {
                ...val,
            }

            if (this.editData) {
                delete newBtn.position;
                this.updateButtonManufacturerItem({id: this.editData?.id, data: newBtn}).then(res => {
                    if (res) {
                        this.toggleDynamicModal()
                        this.getManufacturerItem();
                    }
                });
            } else {
                this.addButtonToManufacturerItem(newBtn).then(res => {
                    if (res) {
                        this.toggleDynamicModal()
                        this.getManufacturerItem();
                    }
                });
            }
        },
        getManufacturerItem() {
            const id = this.$route.params?.id;
            id && this.getManufactureItemsByCode(id).then(res => {
                res.buttons = res?.buttons.sort((a, b) => a?.position - b?.position)
                if (res.id) this.manufacturerItem = res;
            });
        }
    },
    created() {
        this.getManufacturerItem();
    }
}
</script>

<style scoped>

</style>
