<template>
    <loader
        v-if="isLoading"
    />
    <div v-else :class="[
            'add-content-section br-20 mb-20',
            mScreen ? 'ph-8 pv-8' : 'p-10'
        ]"
         style="background: none"
    >
        <draggable
            v-model="contentSections"
            :disabled="!dragEnabled"
            :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }"
            item-key="name"
            class="list-group"
            v-bind="dragOptions"
            handle=".handle"
            @start="dragging = true"
            @end="saveContentPosition"
        >
            <template #item="{ element }">
                <div
                    :class="[
                        'list-group-item',
                        !dragEnabled ? 'not-draggable' : ' grabbable'
                    ]"
                >
                    <div
                        class="row-start"
                        v-show="element.name === SECTION_CONTENT_NAME.VIDEO
                            && uploadedFiles?.videos?.length"
                    >
                        <div
                            :class="[
                                'mb-6',
                                mScreen ? 'row-between' : 'row-baseline row-start'
                            ]"
                        >
                            <h1 class="f-17-black f-extrabold">
                                <span
                                    v-if="dragEnabled && canDeleteOrEdit"
                                    class="tarnsparent-button handle p-1"
                                >
                                    <drag-icon
                                        class="i-black"
                                        size="12"
                                    />
                                </span>
                                {{ element.name }}
                            </h1>
                            <div
                                :class="[
                                    'ml-5 pointer lg-1 sm-3 z-index-2 ',
                                    {'s-align-6': mScreen}
                                ]"
                                @click.prevent="dragging = false, videoExpand = !videoExpand"
                            >
                                <span class="bg-maingreen f-12-white content-counter">
                                    {{ uploadedFiles?.videos?.length }}
                                </span>
                                <arrow-shewron
                                    class="i-green ml-3"
                                    :transform="videoExpand ? -90 : 90"
                                />
                            </div>
                        </div>
                        <div class="row-start" v-show="videoExpand">
                            <div
                                v-for="video in uploadedFiles?.videos"
                                :class="[
                                    'lg-6 sm-12 mb-5',
                                    mScreen ? 'column-center' : 'column-start'
                                ]"
                            >
                                <iframe
                                    :width="iframeSize.width"
                                    :height="iframeSize.height"
                                    :src="`${video.content}`"
                                    loading="lazy"
                                    frameborder="0"
                                    allow="accelerometer;
                                    autoplay;
                                    clipboard-write;
                                    encrypted-media;
                                    gyroscope;
                                    picture-in-picture"
                                    allowfullscreen></iframe>
                                <button
                                    v-if="canDeleteOrEdit"
                                    :class="[
                                            'small-border-button mt-5',
                                            { 'mr-auto' : mScreen }
                                        ]"
                                    @click="deleteContent(video, CONTENT_TYPES.VIDEO)"
                                >
                                    Delete video
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row-start"
                        v-show="element.name === SECTION_CONTENT_NAME.PHOTO
                            && uploadedFiles?.photos?.length"
                    >
                        <div
                            :class="[
                                'mb-6',
                                mScreen ? 'row-between' : 'row-baseline row-start'
                            ]"
                        >
                            <h1 class="f-17-black f-extrabold">
                                <span
                                    v-if="dragEnabled  && canDeleteOrEdit"
                                    class="tarnsparent-button handle p-1"
                                >
                                    <drag-icon
                                        class="i-black"
                                        size="12"
                                    />
                                </span>
                                {{ element.name }}
                            </h1>
                            <div
                                :class="[
                                    'ml-5 pointer lg-1 sm-3 z-index-2 ',
                                    {'s-align-6': mScreen}
                                ]"
                                @click.prevent="dragging = false, photoExpand = !photoExpand"
                            >
                                <span class="bg-maingreen f-12-white content-counter">
                                    {{ uploadedFiles?.photos?.length }}
                                </span>
                                <arrow-shewron
                                    class="i-green ml-3"
                                    :transform="photoExpand ? -90 : 90"
                                />
                            </div>
                        </div>
                        <div
                            class="row-start"
                            v-show="photoExpand"
                        >
                            <div
                                class="column-start lg-3 nb-6 sm-4 mb-5 content-item"
                                v-for="photo in uploadedFiles?.photos"
                                :key="photo.id"
                            >
                                <img
                                    class="content-photo flex-shrink-1 pointer"
                                    loading="lazy"
                                    :src="photo?.media?.[0]?.thumb_url"
                                    @click="showFullImage(photo?.media?.[0]?.url, photo?.media?.[0]?.file_name)"
                                >
                                <button
                                    v-if="canDeleteOrEdit"
                                    @click.stop="deleteContent(photo, CONTENT_TYPES.PHOTO)"
                                    :disabled="photo.id === deleteItemId && deleteLoading"
                                    class="small-border-button mt-2">
                                    {{ photo.id === deleteItemId && deleteLoading ? 'Loading...' : 'Delete photo' }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row-start"
                        v-show="element.name === SECTION_CONTENT_NAME.DOCUMENTS
                            && uploadedFiles?.docs.length"
                    >
                        <div
                            :class="[
                                'mb-6',
                                mScreen ? 'row-between' : 'row-baseline row-start'
                            ]"
                        >
                            <h1 class="f-17-black f-extrabold">
                                <span
                                    v-if="dragEnabled && canDeleteOrEdit"
                                    class="tarnsparent-button handle p-1"
                                >
                                    <drag-icon
                                        class="i-black"
                                        size="12"
                                    />
                                </span>
                                {{ element.name }}
                            </h1>
                            <div
                                :class="[
                                    'ml-5 pointer lg-1 sm-3',
                                    {'s-align-6': mScreen}
                                ]"
                                @click="dragging = false, docuentsExpand = !docuentsExpand"
                            >
                                <span class="bg-maingreen f-12-white content-counter">
                                    {{ uploadedFiles?.docs.length }}
                                </span>
                                <arrow-shewron
                                    class="i-green ml-3"
                                    :transform="docuentsExpand ? -90 : 90"
                                />
                            </div>
                        </div>
                        <div
                            class="row-between"
                            v-show="docuentsExpand"
                        >
                            <div
                                class="row-between row-baseline lg-6 sm-12 content-item"
                                v-for="(doc, index) in uploadedFiles?.docs"
                                :key="doc.id"
                            >
                                <div
                                    :class="[
                                        {'w-95': (index+1) % 2 && !mScreen},
                                        'row-baseline mb-5 bg-grey-11 ph-7 pv-5 br-7 flex-no-wrap content-item_documents'
                                    ]"
                                >
                                    <pdf-icon
                                        class="i-green flex-shrink-0"
                                        height="24"
                                        width="20"
                                    />
                                    <div class="column-start s-align-2 ml-20 ">
                                        <a :href="doc.media[0].url" target="_blank">
                                            <span>
                                                {{ formatFileName(doc.media[0].file_name) }}
                                            </span>
                                        </a>
                                    </div>
                                    <button
                                        v-if="canDeleteOrEdit"
                                        class="transparent-button ml-3 delete-item-button ml-auto"
                                        @click="deleteContent(doc, CONTENT_TYPES.DOCUMENT)"
                                    >
                                        <loader
                                            v-if="doc.id === deleteItemId && deleteLoading"
                                            size="mini"
                                        />
                                        <close-icon
                                            v-else
                                            size="10"
                                            class="i-green"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <modal
            :closeModalOnBackdropClicked="true"
            @close="closePhotoViewModal"
            v-if="showImage"
        >
            <template v-slot:title>
                <h1 class="f-20-black f-extrabold mb-2">
                    Photo
                </h1>
            </template>
            <template #body>
                <div class="w-100">
                    <img
                        v-if="imgVisible && imgUrl"
                        class="content-full-photo"
                        :src="imgUrl"
                    >
                    <loader v-else/>
                </div>
            </template>
        </modal>
    </div>

</template>

<script>
import Modal from "../ui/modal/Modal";
import {CONTENT_TYPES} from "../constants";
import draggable from "vuedraggable";
import ArrowShewron from "../icons/ArrowShewron";
import PdfIcon from "../icons/PdfIcon";
import CloseIcon from "../icons/CloseIcon";
import DragIcon from "../icons/DragIcon";
import {formatFileName} from "../helpers/commonHelpers";
import {mapActions} from "vuex";

const SECTION_CONTENT_NAME = {
    VIDEO: 'Video',
    PHOTO: 'Photo',
    DOCUMENTS: 'Documents'
}

export default {
    name: "ManufacturerContentSection",
    components: {
        ArrowShewron,
        PdfIcon,
        CloseIcon,
        DragIcon,
        Modal,
        draggable
    },
    props: {
        canDeleteOrEdit: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        content: {
            type: [Array, Object],
            default: null
        }
    },
    data() {
        return {
            SECTION_CONTENT_NAME,
            CONTENT_TYPES,
            contentSections: [],
            dragEnabled: true,
            imgVisible: false,
            imgUrl: null,
            showImage: false,
            deleteItemId: null,
            deleteLoading: false,
            photoExpand: false,
            docuentsExpand: false,
            videoExpand: false,
        }
    },
    computed: {
        uploadedFiles() {
            let contentArr = Array.isArray(this.content) ? this.content : [];
            const objData = contentArr.reduce((acc, el) => {
                if (el.type === CONTENT_TYPES.PHOTO) {
                    acc.photos.push(...el?.photos);
                } else if (el.type === CONTENT_TYPES.VIDEO) {
                    acc.videos.push(...el.videos);
                } else if (el.type === CONTENT_TYPES.DOCUMENT) {
                    acc.docs.push(...el.documents);
                }
                return acc;
            }, {docs: [], photos: [], videos: []});
            return objData
        },
        iframeSize() {
            if (!this.mScreen) {
                return {
                    width: 250,
                    height: 'auto'
                }
            } else {
                return {
                    width: 200,
                    height: 'auto'
                }
            }
        },
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        ...mapActions({
            deleteButtonContent: "manufacturer/deleteButtonContent",
            reorderSection: "manufacturer/reorderSection"
        }),
        formatFileName,
        closePhotoViewModal() {
            this.showImage = false;
            this.imgUrl = null;
            this.imgVisible = false;
        },
        showFullImage(imageUrl, fileName) {
            if (imageUrl) {
                this.showImage = true;
                this.imgUrl = imageUrl;

                const image = document.createElement('img');
                image.src = imageUrl;
                image.onload = () => this.imgVisible = true;
            }

            if (fileName) {
                this.viewedFileName = fileName;
            }
        },
        deleteContent(val, type) {
            this.deleteLoading = true;
            this.deleteItemId = val.id;

            let params = {};

            switch (type) {
                case CONTENT_TYPES.PHOTO:
                    params['section_type'] = CONTENT_TYPES.PHOTO;
                    break;
                case CONTENT_TYPES.DOCUMENT:
                    params['section_type'] = CONTENT_TYPES.DOCUMENT;
                    break;
                case CONTENT_TYPES.VIDEO:
                    params['section_type'] = CONTENT_TYPES.VIDEO;
                    break;
            }
            this.deleteButtonContent({id: val.id, params}).then(res => {
                this.$emit('onReload')
                this.deleteItemId = null;
                this.deleteLoading = false;
            })

        },
        saveContentPosition() {
            this.dragging = false;
            if (this.contentSections.length > 1) {
                let reorderedSection = [];
                this.contentSections.forEach((el, index) => {
                    el.position = index;
                    reorderedSection.push({
                        id: el.id,
                        position: el.position
                    })
                });
                let params = {
                    sections: reorderedSection
                };
                this.reorderSection(params)
            }
        },
        setContent(content) {
            const arr = JSON.parse(JSON.stringify(content));
            const contentParse = arr.reduce((acc, el) => {
                const obj = acc.find(e => e.type === el.type);
                const keys = {
                    [CONTENT_TYPES.PHOTO]: 'photos',
                    [CONTENT_TYPES.VIDEO]: 'videos',
                    [CONTENT_TYPES.DOCUMENT]: 'documents',
                }
                if (obj) {
                    const key = keys[el.type];
                    obj?.[key].push(...el?.[key])
                } else {
                    acc.push(el)
                }
                return acc;
            }, []) ?? [];
            this.contentSections = contentParse.sort((a, b) => a.position - b.position);
        }
    },
    created() {
        if (Array.isArray(this.content)) this.setContent(this.content);
    }
}
</script>
