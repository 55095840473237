<template>
    <modal
        :closeModalOnBackdropClicked="false"
        @close="$emit('close')"
        class="add-links-modal"
    >
        <template v-slot:title>
            <h1 class="f-20-black f-extrabold mb-2">{{ titleModal }}</h1>
        </template>
        <template #body>
            <perfect-scrollbar>
                <Form
                    class="h-100 column-start pr-7 g-2"
                    :validation-schema="schema"
                    @submit="handleSaveLinks"
                    @invalid-submit="onInvalidSubmit"
                    v-slot="{ errors }"
                >
                    <div class="lg-12 md-12 sm-12">
                        <form-input
                            id="name"
                            class="w-100"
                            name="name"
                            placeholder="Name"
                            v-model="formData.name"
                            :errors="errors"
                        />
                    </div>
                    <div class="lg-12 md-12 sm-12" v-if="type === TYPE_FIELDS.phone">
                        <form-input
                            id="phone_number"
                            class="w-100"
                            name="phone_number"
                            placeholder="Phone number"
                            v-maska="'+1 (###) ###-####'"
                            v-model="formData.phone_number"
                            :errors="errors"
                        />
                    </div>
                    <div class="lg-12 md-12 sm-12" v-if="type === TYPE_FIELDS.link">
                        <form-input
                            id="link"
                            class="w-100"
                            name="link"
                            placeholder="Hyperlink"
                            v-model="formData.link"
                            :errors="errors"
                        />
                    </div>
                    <div class="lg-12 md-12 sm-12">
                        <form-input
                            id="bgColor"
                            label="Button color"
                            class="w-100 color-input"
                            name="bgColor"
                            type="color"
                            v-model="formData.bgColor"
                            :errors="errors"
                        />
                    </div>
                    <div class="lg-12 md-12 sm-12">
                        <form-input
                            id="txtColor"
                            label="Text color"
                            class="w-100 color-input"
                            name="txtColor"
                            type="color"
                            v-model="formData.txtColor"
                            :errors="errors"
                        />
                    </div>
                    <div class="row-center mt-2">
                        <button
                            class="primary-button
                            manufacturer-items__buttons-item"
                            :style="{
                            backgroundColor: formData.bgColor,
                             color: formData.txtColor, minHeight: '46px',
                             paddingLeft: type === TYPE_FIELDS.phone ? '70px' : '30px'
                             }"
                        >
                            <div
                                v-if="type === TYPE_FIELDS.phone"
                                class="phone-icon-container"
                                :style="{borderColor: formData.txtColor}"
                            >
                                <phone-icon :style="{fill: formData.txtColor}"/>
                            </div>
                            {{formData.name ?? ''}}
                        </button>
                    </div>
                    <div class="row-end mt-8 mb-4">
                        <primary-button
                            class="mr-5"
                            buttonType="submit"
                            :disabled="isLoading"
                            :is-loading="isLoading"
                        >
                            Save
                        </primary-button>
                    </div>
                </Form>
            </perfect-scrollbar>
        </template>
    </modal>
</template>

<script>
import PrimaryButton from "../../ui/base/PrimaryButton";
import * as yup from "yup";
import "yup-phone";
import Modal from "../../ui/modal/Modal";
import {Form} from "vee-validate";
import {TYPE_FIELDS} from "../../constants";
import PhoneIcon from "../../icons/PhoneIcon";

export default {
    name: "AddButtonManufactureModal",
    components: {
        PrimaryButton,
        Modal,
        Form,
        PhoneIcon
    },
    data() {
        return {
            TYPE_FIELDS,
            formData: {
                phone_number: '',
                name: '',
                link: '',
                bgColor: '#4eac8f',
                txtColor: '#ffffff'
            }
        }
    },
    props: {
        type: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        editData: {
            type: Object,
            default: null
        }
    },
    methods: {
        handleSaveLinks(values) {
            this.$emit('onUpload', values, this.type);
        },
        onInvalidSubmit() {

        },
        initData(data) {
            const type = data?.type;
            if (!type) return;
            if (type === TYPE_FIELDS.link){
                this.formData.link = data.data?.link;
            }else if (type === TYPE_FIELDS.phone){
                this.formData.phone_number = data.data?.phone_number
            }
            this.formData.bgColor = data?.data?.bgColor ?? '#4eac8f';
            this.formData.txtColor = data?.data?.txtColor ?? '#ffffff';
            this.formData.name = data?.name;
        }
    },
    computed: {
        titleModal() {
            const event = this.editData ? 'Edit' : 'Add';
            const titleModal = {
                [TYPE_FIELDS.link]: `${event} link`,
                [TYPE_FIELDS.phone]: `${event} phone`,
                [TYPE_FIELDS.contentButton]: `${event} content button`
            }
            return titleModal?.[this.type] ?? '';
        },
        schema() {
            let baseRules = {
                name: yup.string().required().label('Named phone'),
            }
            if (this.type === TYPE_FIELDS.phone) {
                baseRules.phone_number = yup.string().phone("US").required().label('Phone number');
            } else if (this.type === TYPE_FIELDS.link) {
                baseRules.link = yup.string().url('Website link must be a valid URL. Copy and paste the link from your browser');
            }
            return yup.object(baseRules);
        },
    },
    created() {
        this.editData && this.initData(this.editData);
    }
}
</script>

<style scoped>

</style>
