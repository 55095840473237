<template>
    <modal @close="$emit('onClose')">
        <template v-slot:title>
            <h1 class="f-20-black f-extrabold mb-2">Remove {{name}}</h1>
        </template>
        <template #body>
            Do you really want to delete?
        </template>
        <template #footer>
            <div class="row-between mt-5">
                <button class="border-button" @click.stop="$emit('close')">Cancel</button>
                <button class="danger-button" @click.stop="$emit('submit')">Ok</button>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from "./Modal";

export default {
    name: "RemoveModal",
    components: {
        Modal
    },
    props: {
        name: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>

</style>
